import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { CertificateService } from 'src/app/services/certificate.service';
import { CreateCertificatesComponent } from '../create-certificates/create-certificates.component';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-certificates-details',
  templateUrl: './certificates-details.component.html'
})
export class CertificatesDetailsComponent implements OnInit {
  dataLoading = false;
  actionLoading = false;
  certificateId: any;
  details: any;

  constructor(
    public dialog: MatDialog,
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private service: CertificateService
  ) { }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe(params => {
      if (params.get('id')) {
        this.certificateId = params.get('id');
        this.getDetails();
      }
    });
  }

  getDetails() {
    this.dataLoading = true;
    this.service.getCertificateDetails(this.certificateId).subscribe(
      data => {
        this.dataLoading = false;
        this.details = data;
        console.log(this.details);
      }, err => {
        console.error(err);
      });
  }

  changeStatus(status) {
    Swal.fire({
      title: this.translate.instant('messages.Are you sure?'),
      text: '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('general.yes'),
      cancelButtonText: this.translate.instant('general.Cancel')
    }).then((result) => {
      if (result.value) {
        this.actionLoading = true;
        const obj = {
          certificateId: this.details.certificateId,
          certificateStatus: status
        };
        this.service.changeCertificateStatus(obj).subscribe(
          data => {
            this.actionLoading = false;
            this.getDetails();
          }, err => {
            this.actionLoading = false;
            console.error(err);
          });
      }
    });
  }

  allowSearch() {
    this.actionLoading = true;
    const obj = {
      certificateId: this.details.certificateId,
      AllowSearch: this.details.showInSearch
    };
    this.service.AllowSearch(obj).subscribe(
      data => {
        this.actionLoading = false;
        this.getDetails();
      }, err => {
        this.actionLoading = false;
        console.error(err);
      });
  }

  openEditDialog() {
    const dialogRef = this.dialog.open(CreateCertificatesComponent, {
      width: '600px',
      data: {record: this.details}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        Swal.fire({
          title: this.translate.instant('messages.Success'),
          text: this.translate.instant('messages.Certificate updated successfully'),
          icon: 'success',
          confirmButtonText: this.translate.instant('messages.ok')
        }).then(() => {
          this.getDetails();
        });
      }
    });
  }

}
