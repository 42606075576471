import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import localeAr from '@angular/common/locales/ar';
registerLocaleData(localeAr);

import { DndDirective } from './helpers/dnd.directive';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { ErrorInterceptor } from './helpers/error.interceptor';

import { DemoMaterialModule } from './helpers/material-modules';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { AppDateAdapter, APP_DATE_FORMATS } from './helpers/format-datepicker';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}
import { ChartsModule, ThemeService } from 'ng2-charts';
import { SanitizeHtmlPipePipe } from './helpers/sanitize-html-pipe.pipe';
import { OnlynumberDirective } from './helpers/onlynumber.directive';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ShortNamePipe } from './helpers/shortName.pipe';
import {TimeAgoPipe} from 'time-ago-pipe';
import { RecaptchaModule, RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { BasicLayoutComponent } from './layout/basic-layout/basic-layout.component';
import { AdminLayoutComponent } from './layout/admin-layout/admin-layout.component';

import { HeaderComponent } from './layout/admin-layout/header/header.component';
import { SideBarComponent } from './layout/admin-layout/sidebar/sidebar.component';

import { FileManagerComponent } from './features/partial/file-manager/file-manager.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { LoginComponent } from './features/authentication/login/login.component';
import { ResetPasswordComponent } from './features/authentication/reset-password/reset-password.component';
import { ForgetPasswordComponent } from './features/authentication/forget-password/forget-password.component';
import { ChangePasswordComponent } from './features/authentication/change-password/change-password.component';
import { DashboardComponent } from './features/dashboard/dashboard.component';
import { UsersComponent } from './features/users/users.component';
import { CreateUserComponent } from './features/users/create-user/create-user.component';
import { UserDetailsComponent } from './features/users/user-details/user-details.component';
import { ConfigurationComponent } from './features/configuration/configuration.component';
import { LanguageConfigurationComponent } from './features/configuration/language-configuration/language-configuration.component';
import { CountriesConfigurationComponent } from './features/configuration/countries-configuration/countries-configuration.component';
import { SectorsConfigurationComponent } from './features/configuration/sectors-configuration/sectors-configuration.component';
import { CreateLanguageComponent } from './features/configuration/language-configuration/create-language/create-language.component';
import { CreateSectorsComponent } from './features/configuration/sectors-configuration/create-sectors/create-sectors.component';
import { CreateCountriesComponent } from './features/configuration/countries-configuration/create-countries/create-countries.component';

import { FilesViewerComponent } from './features/partial/files-viewer/files-viewer.component';
import { CABDetailsComponent } from './features/cabs/cabdetails/cabdetails.component';
import { CABSComponent } from './features/cabs/cabs.component';
import { EntitiesComponent } from './features/entities/entities.component';
import { EntityFormComponent } from './features/entities/entity-form/entity-form.component';
import { PaymentRequestsComponent } from './features/payment-requests/payment-requests.component';
import { PaymentRequestDetailsComponent } from './features/payment-requests/payment-request-details/payment-request-details.component';
import { EntityBranchFormComponent } from './features/entities/entity-form/entity-branch-form/entity-branch-form.component';
// tslint:disable-next-line: max-line-length
import { EntityRepresentativeFormComponent } from './features/entities/entity-form/entity-representative-form/entity-representative-form.component';
import { EntityDetailsComponent } from './features/entities/entity-details/entity-details.component';
import { DuePaymentsComponent } from './features/due-payments/due-payments.component';
import { CheckoutPaymentComponent } from './features/due-payments/checkout-payment/checkout-payment.component';
import { ChooseInvoicesComponent } from './features/due-payments/choose-invoices/choose-invoices.component';
import { DiscussionsComponent } from './features/discussions/discussions.component';
import { DiscussionsListComponent } from './features/discussions/discussions-list/discussions-list.component';
import { CreateDiscussionComponent } from './features/discussions/create-discussion/create-discussion.component';
import { DiscussionDetailsComponent } from './features/discussions/discussion-details/discussion-details.component';
import { CertificatesComponent } from './features/certificates/certificates.component';
import { CertificatesDetailsComponent } from './features/certificates/certificates-details/certificates-details.component';
import { CreateCertificatesComponent } from './features/certificates/create-certificates/create-certificates.component';
import { EntityInfoFormComponent } from './features/entities/entity-form/entity-info-form/entity-info-form.component';
import { UpdateEntityDialogComponent } from './features/entities/entity-details/update-entity-dialog/update-entity-dialog.component';
import { UpdateBranchesDialogComponent } from './features/entities/entity-details/update-branches-dialog/update-branches-dialog.component';
// tslint:disable-next-line: max-line-length
import { UpdateRepresentativeDialogComponent } from './features/entities/entity-details/update-representative-dialog/update-representative-dialog.component';
import { AnnouncementsComponent } from './features/announcements/announcements.component';
import { CreateAnnouncementComponent } from './features/announcements/create-announcement/create-announcement.component';
import { CabsFormComponent } from './features/cabs/cabs-form/cabs-form.component';
import { WalletTransDialogComponent } from './features/cabs/wallet-trans-dialog/wallet-trans-dialog.component';
import { RenewExpirationDialogComponent } from './features/cabs/cabdetails/renew-expiration-dialog/renew-expiration-dialog.component';
import { RepresentativesComponent } from './features/representatives/representatives.component';
import { CabPaymentComponent } from './features/cabs/cabdetails/cab-payment/cab-payment.component';
import { RegisterComponent } from './features/authentication/register/register.component';
import { SearchCABComponent } from './features/search-cab/search-cab.component';
import { ActivationComponent } from './features/authentication/activation/activation.component';
import { PaymentConfirmationDialogComponent } from './features/due-payments/payment-confirmation-dialog/payment-confirmation-dialog.component';
import { GacComponent } from './features/gac/gac.component';
import { GacFormComponent } from './features/gac/gac-form/gac-form.component';
import { NationalityConfigurationsComponent } from './features/configuration/nationality-configurations/nationality-configurations.component';
import { CreateNationalityConfigurationComponent } from './features/configuration/nationality-configurations/create-nationality-configuration/create-nationality-configuration.component';
import { ABTypesComponent } from './features/configuration/abtypes/abtypes.component';
import { CreateabtypesComponent } from './features/configuration/abtypes/createabtypes/createabtypes.component';

@NgModule({
  declarations: [
    AppComponent,
    DndDirective,
    SanitizeHtmlPipePipe,
    OnlynumberDirective,
    TimeAgoPipe,
    ShortNamePipe,

    BasicLayoutComponent,
    AdminLayoutComponent,
    SideBarComponent,
    HeaderComponent,
    FileManagerComponent,
    FilesViewerComponent,
    DashboardComponent,

    LoginComponent,
    ResetPasswordComponent,
    ForgetPasswordComponent,
    ChangePasswordComponent,
    UsersComponent,
    CreateUserComponent,
    UserDetailsComponent,
    ConfigurationComponent,
    LanguageConfigurationComponent,
    CountriesConfigurationComponent,
    SectorsConfigurationComponent,
    CreateLanguageComponent,
    CreateSectorsComponent,
    CreateCountriesComponent,
    CABDetailsComponent,
    CABSComponent,
    EntitiesComponent,
    EntityFormComponent,
    PaymentRequestsComponent,
    PaymentRequestDetailsComponent,
    EntityBranchFormComponent,
    EntityRepresentativeFormComponent,
    DuePaymentsComponent,
    CheckoutPaymentComponent,
    ChooseInvoicesComponent,
    DiscussionsComponent,
    DiscussionsListComponent,
    CreateDiscussionComponent,
    DiscussionDetailsComponent,
    CertificatesComponent,
    CertificatesDetailsComponent,
    CreateCertificatesComponent,
    EntityDetailsComponent,
    EntityInfoFormComponent,
    UpdateEntityDialogComponent,
    UpdateBranchesDialogComponent,
    UpdateRepresentativeDialogComponent,
    AnnouncementsComponent,
    CreateAnnouncementComponent,
    CabsFormComponent,
    WalletTransDialogComponent,
    RenewExpirationDialogComponent,
    RepresentativesComponent,
    CabPaymentComponent,
    CabPaymentComponent,
    RegisterComponent,
    SearchCABComponent,
    ActivationComponent,
    PaymentConfirmationDialogComponent,
    GacComponent,
    GacFormComponent,
    NationalityConfigurationsComponent,
    CreateNationalityConfigurationComponent,
    ABTypesComponent,
    CreateabtypesComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    DemoMaterialModule,
    FormsModule,
    CKEditorModule,
    ChartsModule,
    ReactiveFormsModule,
    HttpClientModule,
    InfiniteScrollModule,
    RecaptchaModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: '6LfhpvQZAAAAAJdDbvbCkFKvycgghhDsumMnPZYg',
        useValue: localStorage.getItem('language') || 'en'
      } as RecaptchaSettings,
    },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS},
    { provide: LOCALE_ID, useValue: localStorage.getItem('language') || 'en' },
    ThemeService
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    EntityFormComponent,
    CreateUserComponent,
    CreateLanguageComponent,
    CreateSectorsComponent,
    CreateCountriesComponent,
    PaymentRequestDetailsComponent,
    CheckoutPaymentComponent,
    ChooseInvoicesComponent,
    CreateDiscussionComponent,
    CreateCertificatesComponent,
    UpdateEntityDialogComponent,
    UpdateBranchesDialogComponent,
    UpdateRepresentativeDialogComponent,
    CreateAnnouncementComponent,
    CabsFormComponent,
    WalletTransDialogComponent,
    RenewExpirationDialogComponent,
    CabPaymentComponent,
    PaymentConfirmationDialogComponent,
    GacFormComponent,
    CreateNationalityConfigurationComponent,
    CreateabtypesComponent
  ],
})
export class AppModule { }
