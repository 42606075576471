import { Component, Inject, OnInit } from '@angular/core';

import { CabsService } from 'src/app/services/cabs.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  cabId: number;
}
@Component({
  selector: 'app-wallet-trans-dialog',
  templateUrl: './wallet-trans-dialog.component.html'
})
export class WalletTransDialogComponent implements OnInit {
  dataLoading = false;
  transList = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private service: CabsService,
  ) { }

  ngOnInit() {
    this.getCabTrans();
  }

  getCabTrans() {
    this.dataLoading = true;
    this.service.GetWalletHistory(this.data.cabId).subscribe(
      data => {
        this.dataLoading = false;
        this.transList = data as [];
        // console.log(this.transList);
      }, err => {
        console.error(err);
      });
  }
}
