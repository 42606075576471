import { Component, OnInit, Inject, ViewChild, ElementRef} from '@angular/core';
import { FormGroup, FormBuilder, Validators, NgModel } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LookupManagementService } from 'src/app/services/lookup-management.service';
import { oneRecord } from 'src/app/models/lookupsManagement/oneRecord';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

export interface DialogData {
  selectedAbtype: oneRecord;
  scopeType: number;
  parentId: number;
}

@Component({
  selector: 'app-createabtypes',
  templateUrl: './createabtypes.component.html'
})
export class CreateabtypesComponent implements OnInit {

  loading = false;

  AbtypeFormGroup: FormGroup;

  AbTypeModel: oneRecord = new oneRecord();

  AbTypeUpdatedModel: oneRecord = new oneRecord();

  constructor(
    public dialogRef: MatDialogRef<CreateabtypesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private lookupManagementService: LookupManagementService) { }


  ngOnInit() {
    this.buildForms();
  }

  buildForms() {
    this.AbtypeFormGroup = this.formBuilder.group({
      NameEng: ['', [Validators.required, Validators.pattern('^[0-9a-zA-Z ]+$')]],
      NameAr: ['', [
          Validators.required,
          // Validators.pattern('^(?=.*[\u0600-\u06FF])[\u0600-\u06FF0-9 _-]+$')
        ]
      ],
    });

    if (this.data.selectedAbtype != null) {
      this.setValuesForm();
    }
  }

  setValuesForm() {
    this.AbtypeFormGroup.controls.NameEng.setValue(this.data.selectedAbtype.englishName);
    this.AbtypeFormGroup.controls.NameAr.setValue(this.data.selectedAbtype.arabicName);
  }

  submitAbType() {
    if (this.AbtypeFormGroup.valid) {
      this.loading = true;
      if (this.data.selectedAbtype == null) {
        this.AddType();
      } else {
        this.UpdateType();
      }
    }
  }

  AddType() {
    this.AbTypeModel.childs = [];
    this.AbTypeModel.englishName = this.AbtypeFormGroup.controls.NameEng.value;
    this.AbTypeModel.arabicName = this.AbtypeFormGroup.controls.NameAr.value;
    this.AbTypeModel.parentId = this.data.parentId;
    if (this.data.scopeType === 1) {
      this.AddCertificateScope(this.AbTypeModel);
    } else if (this.data.scopeType === 2) {
      this.AddScope(this.AbTypeModel);
    } else {
      this.AddABType(this.AbTypeModel);
    }
  }

  AddABType(AbTypeModel) {
    this.lookupManagementService.AddABType(AbTypeModel).subscribe(
      data => {
        this.loading = false;
        this.dialogRef.close(true);
      }, err => {
        this.loading = false;
        console.error(err);
    });
  }

  AddCertificateScope(AbTypeModel) {
    this.lookupManagementService.AddCertificateScope(AbTypeModel).subscribe(
      data => {
        this.loading = false;
        this.dialogRef.close(true);
      }, err => {
        this.loading = false;
        console.error(err);
    });
  }

  AddScope(AbTypeModel) {
    this.lookupManagementService.AddScope(AbTypeModel).subscribe(
      data => {
        this.loading = false;
        this.dialogRef.close(true);
      }, err => {
        this.loading = false;
        console.error(err);
    });
  }

  ///////////////////////////////////////////////

  UpdateType() {
    this.AbTypeUpdatedModel.childs = [];

    this.AbTypeUpdatedModel.id = this.data.selectedAbtype.id;
    this.AbTypeUpdatedModel.englishName = this.AbtypeFormGroup.controls.NameEng.value;
    this.AbTypeUpdatedModel.arabicName = this.AbtypeFormGroup.controls.NameAr.value;
    if (this.data.scopeType === 1) {
      this.UpdateCertificateScope(this.AbTypeUpdatedModel);
    } else if (this.data.scopeType === 2) {
      this.UpdateScope(this.AbTypeUpdatedModel);
    } else {
      this.UpdateABType(this.AbTypeUpdatedModel);
    }
  }

  UpdateCertificateScope(AbTypeUpdatedModel) {
    this.lookupManagementService.UpdateCertificateScope(AbTypeUpdatedModel).subscribe(
      data => {
        this.loading = false;
        this.dialogRef.close(true);
      }, err => {
        this.loading = false;
        console.error(err);
    });
  }

  UpdateScope(AbTypeUpdatedModel) {
    this.lookupManagementService.UpdateScope(AbTypeUpdatedModel).subscribe(
      data => {
        this.loading = false;
        this.dialogRef.close(true);
      }, err => {
        this.loading = false;
        console.error(err);
    });
  }

  UpdateABType(AbTypeUpdatedModel) {
    this.lookupManagementService.UpdateABType(AbTypeUpdatedModel).subscribe(
      data => {
        this.loading = false;
        this.dialogRef.close(true);
      }, err => {
        this.loading = false;
        console.error(err);
    });
  }

  hasError(formGroup, controlName: string, errorName: string) {
    return formGroup.controls[controlName].hasError(errorName);
  }
}
