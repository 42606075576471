import { Component, OnInit } from '@angular/core';

import { CreateCertificatesComponent } from './create-certificates/create-certificates.component';
import { CertificateListFilter } from 'src/app/models/certificates/CertificateListFilter';
import { CertificateListData } from 'src/app/models/certificates/CertificateListData';
import { sortList } from 'src/app/models/certificates/sortList';
import { statusList } from 'src/app/models/certificates/statusList';
import { CertificateService } from 'src/app/services/certificate.service';

import { MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-certificates',
  templateUrl: './certificates.component.html'
})
export class CertificatesComponent implements OnInit {
  dataLoading = false;
  showFilter = false;
  showSearch = false;

  sortItems = [
    { name: '', value: null },
    { name: this.translate.instant('Certificate.Certificate Number'), value: sortList.CertificateNumber },
    { name: this.translate.instant('general.Entity'), value: sortList.Entity },
    { name: this.translate.instant('forms.Expiration Date'), value: sortList.ExpirationDate },
    { name: this.translate.instant('forms.Issue Date'), value: sortList.IssueDate },
    { name: this.translate.instant('Certificate.Scope'), value: sortList.Scope },
    { name: this.translate.instant('general.Status'), value: sortList.Status }
  ];
  statusList = [
    { name: this.translate.instant('general.New'), value: statusList.New },
    // { name: this.translate.instant('general.Generated Invoice'), value: statusList.GeneratedInvoice },
    { name: this.translate.instant('general.Paid Due Payment'), value: statusList.PaidDuePayment },
    { name: this.translate.instant('general.Authentication Report'), value: statusList.AuthenticationReport },
    { name: this.translate.instant('general.Active'), value: statusList.Active },
    { name: this.translate.instant('general.Suspended'), value: statusList.Suspended },
    { name: this.translate.instant('general.Expired'), value: statusList.Expired }
  ];

  // table
  pageCount = 0;
  manualPage = 0;
  recordsData: any = {};

  filterModel: CertificateListFilter = new CertificateListFilter();

  constructor(
    private translate: TranslateService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private service: CertificateService
  ) { }

  ngOnInit() {
    this.filterModel.pageSize = 10;
    this.getAll(null);
    // this.openCreateDialog();
  }

  getAll(event) {
    this.route.queryParams.subscribe(params => {
      if (params.cab) {
        this.filterModel.CABId = Number(params.cab);
      }
      if (params.entity) {
        this.filterModel.certifiedEntityId = Number(params.entity);
      }
    });
    this.dataLoading = true;
    if (event) {
      this.filterModel.pageNumber = event.pageIndex;
    } else {
      this.filterModel.pageNumber = 0;
    }
    this.service.getAll(this.filterModel).subscribe(
      data => {
        this.dataLoading = false;
        if (data) {
          this.recordsData = data as CertificateListData;
          this.pageCount = Math.ceil(data.totalCount / data.pageSize);
          this.manualPage = Number(data.pageIndex) + 1;
        }
      },
      error => {
        this.dataLoading = false;
    });
  }

  sortRecords(selected) {
    this.filterModel.sortItems = Number(selected.target.value);
    this.getAll(null);
  }

  // SEARCH
  makeSearch() {
    if (!this.showSearch) {
      this.showSearch = true; // Show input
    } else {
      if (this.filterModel.searchText) {
        this.getAll(null);
      }
    }
  }
  makeFilter() {
    this.getAll(null);
  }
  resetAfterClear() {
    if (!this.filterModel.searchText) {
      this.resetSearch();
    }
  }
  resetSearch() {
    this.filterModel.searchText = '';
    this.getAll(null);
  }

  updatePage(event) {
    if (event.target.value <= this.pageCount) {
      this.filterModel.pageNumber = event.target.value - 1;
      this.getAll(null);
    }
  }

  initName(name) {
    if (!name) { return ''; }
    let initials = name.match(/\b\w/g) || [];
    initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
    return initials;
  }

  openCreateDialog() {
    const dialogRef = this.dialog.open(CreateCertificatesComponent, {
      width: '600px',
      data: {create: true}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        Swal.fire({
          title: this.translate.instant('messages.Success'),
          text: this.translate.instant('messages.Certificate created successfully'),
          icon: 'success',
          confirmButtonText: this.translate.instant('messages.ok'),
        }).then(() => {
          this.getAll(null);
        });
      }
    });
  }
}
