import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html'
})
export class ForgetPasswordComponent implements OnInit {
  forgetPassForm: FormGroup;
  loading = false;
  msgObj = {msg: '', type: ''};

  constructor(
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private translate: TranslateService
    ) { }

  ngOnInit() {
    this.forgetPassForm = this.formBuilder.group({
      email: ['', [
        Validators.required,
        Validators.email,
        Validators.pattern('[a-zA-Z0-9._]{1,}@[a-zA-Z0-9.-]{2,}[.]{1}[a-zA-Z]{2,}'),
      ]]
    });
  }

  forgetPassword() {
    if (this.forgetPassForm.valid) {
      this.loading = true;
      this.authenticationService.forgetPassword(this.forgetPassForm.controls.email.value).subscribe(
        data => {
           this.loading = false;
           this.msgObj.msg = this.translate.instant('auth.Send-successfully-check-email');
           this.msgObj.type = 'success';
        },
        error => {
          this.loading = false;
          this.msgObj.type = 'danger';
          if (error.code === -1) {
            this.msgObj.msg = this.translate.instant('forms.email-not-correct');
          } else {
            this.msgObj.msg = this.translate.instant('forms.Try-again');
            // this.msgObj.msg = this.translate.instant('forms.email-not-correct');
          }
        });
    }
  }

  hasError(controlName: string, errorName: string) {
    return this.forgetPassForm.controls[controlName].hasError(errorName);
  }
}
