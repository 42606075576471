import { Component, Inject, OnInit } from '@angular/core';
import { CabsService } from 'src/app/services/cabs.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

export interface DialogData {
  record: any;
}

@Component({
  selector: 'app-renew-expiration-dialog',
  templateUrl: './renew-expiration-dialog.component.html'
})
export class RenewExpirationDialogComponent implements OnInit {
  loading = false;
  today = new Date();
  expirationDate = '';

  constructor(
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<RenewExpirationDialogComponent>,
    private service: CabsService
  ) { }

  ngOnInit() {
  }

  save() {
    this.loading = true;
    const obj = {
      entityId: this.data.record.entityId,
      expirationDate: this.expirationDate
    };
    this.service.RenewEntity(obj).subscribe(
      data => {
        this.loading = false;
        Swal.fire({
          title: this.translate.instant('messages.Success'),
          text: this.translate.instant('CABs.Accreditation updated successfully'),
          icon: 'success',
          confirmButtonText: this.translate.instant('messages.ok')
        });
        this.dialogRef.close(true);
      }, err => {
        this.loading = false;
        console.error(err);
      });
  }

}
