import { Component, ElementRef, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AsyncValidatorFn, AbstractControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { CabsService } from 'src/app/services/cabs.service';
import { LookupsService } from 'src/app/services/lookups.service';

import { CAB } from 'src/app/models/cabs/CAB';
import { Scope } from 'src/app/models/lookups/Scope';
import { City } from 'src/app/models/lookups/City';
import { Country } from 'src/app/models/lookups/Country';

import { MatAutocomplete } from '@angular/material/autocomplete';
import { MatChipList } from '@angular/material/chips';
import { NestedTreeControl } from '@angular/cdk/tree';
import { ArrayDataSource, SelectionModel } from '@angular/cdk/collections';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

export interface DialogData {
  record: CAB;
}

@Component({
  selector: 'app-cabs-form',
  templateUrl: './cabs-form.component.html'
})
export class CabsFormComponent implements OnInit {
  loading = false;
  commercialNoLoader = false;
  isCreate = true;

  formGroup: FormGroup;
  isSubmitted = false;
  countries: Country[] = [];
  cities: City[] = [];
  public websiteReg = /(http|ftp|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:\/~+#-]*[\w@?^=%&amp;\/~+#-])?/;
  today = new Date();

  // image file
  logo: any = {};
  logoSrc: string;
  maxFileSize = 0.8;
  fileTypeError = false;
  fileSizeError = false;

  //////// Scopes field config /////////////////
  selectedScopes = [];
  showScopesTree = false;
  scopeLoading = false;
  @ViewChild('scopeInput', {static: false}) scopeInput: ElementRef<HTMLInputElement>;
  @ViewChild('scopeAuto', {static: false}) scopeMatAutocomplete: MatAutocomplete;
  @ViewChild('chipScopesList', {static: false}) chipScopesList: MatChipList;
  scopeChipsErrorMsg: string;
  filteredScopes: Observable<{}[]>;
  scopes = [];
  ABTypes = [];
  dataBind = false;
  /** The selection for checklist */
  scopesSelection = new SelectionModel<Scope>(true /* multiple */);
  SCOPE_TREE_DATA: Scope[] = [];
  scopesTreeControl = new NestedTreeControl<Scope> (node => node.children);
  scopesDataSource = new ArrayDataSource(this.SCOPE_TREE_DATA);
  hasChild = (_: number, node: Scope) => !!node.children && node.children.length > 0;
  ////////// End Scopes field config //////////////

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<CabsFormComponent>,
    private service: CabsService,
    private formBuilder: FormBuilder,
    private lookupsService: LookupsService,
    private translate: TranslateService
  ) { }
  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.formGroup = this.formBuilder.group({
      enName: ['', [
          Validators.required,
          Validators.pattern('^[0-9a-zA-Z ]+$')
        ]
      ],
      arName: ['', [Validators.pattern('^(?=.*[\u0600-\u06FF])[\u0600-\u06FF0-9 _-]+$')]],
      commercialNo: ['', Validators.required, [this.uniqueCertificateNoValidator()]],
      issuingDate: ['', Validators.required],
      expirationDate: ['', Validators.required],
      scopes: ['', Validators.required],
      email: ['', [
          Validators.email,
          Validators.pattern('[a-zA-Z0-9._]{1,}@[a-zA-Z0-9.-]{2,}[.]{1}[a-zA-Z]{2,}'),
          Validators.required
        ]
      ],
      country: ['', Validators.required],
      city: ['', Validators.required],
      enAddress: ['', Validators.required],
      arAddress: ['']
    });

    this.formGroup.get('scopes').statusChanges.subscribe(
      status => {
        this.chipScopesList.errorState = status === 'INVALID';
      }
    );
    // this.handelChipsFilters();
    // edit mode
    if (this.data.record && this.data.record.entityId) {
      this.isCreate = false;
      this.bindDataToUpdate();
    }
    ////////////////////////////////////////////////////
    this.getCountries();
    this.getAbTypes();
  }

  uniqueCertificateNoValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<{ [key: string]: any } | null> => {
      if (control.value) { this.commercialNoLoader = true; }
      return this.service.checkUniqueCommercialNo(control.value)
      .pipe(
        map(res => {
          this.commercialNoLoader = false;
          if (!this.isCreate) { return; }
          if (res) {
            return { commercialNoExist: true };
          }
        })
      );
    };
  }

  getCountries() {
    this.lookupsService.getCountries().subscribe(
      data => {
        this.countries = data as Country[];
        if (!this.isCreate) {
          this.getCities(this.data.record.countryId);
        }
      }, err => {
        console.error(err);
      });
  }
  getCities(countryId) {
    this.lookupsService.getCities(countryId).subscribe(
      data => {
        this.cities = data as City[];
      }, err => {
        console.error(err);
      });
  }
  getAbTypes() {
    this.scopeLoading = true;
    const textVal = this.formGroup.get('scopes').value || '';
    this.lookupsService.GetAbTypes(textVal).subscribe(
      data => {
        this.scopeLoading = false;
        this.SCOPE_TREE_DATA = data as Scope[];
        this.scopesDataSource = new ArrayDataSource(this.SCOPE_TREE_DATA);

        this.checkInScopesSource(this.selectedScopes, this.selectedScopes);
        if (!this.isCreate && !this.dataBind) {
          this.dataBind = true;
          this.checkInScopesSource(this.data.record.entityAPTypes, this.data.record.entityScopes);
          this.selectedScopes = this.scopesSelection.selected;
        }
      }, err => {
        console.error(err);
      });
  }
  hasError(formGroup, controlName: string, errorName: string) {
    return formGroup.controls[controlName].hasError(errorName);
  }

  bindDataToUpdate() {
    this.logoSrc = this.data.record.logo;
    this.formGroup.controls.enName.setValue(this.data.record.englishName);
    this.formGroup.controls.arName.setValue(this.data.record.arabicName);
    this.formGroup.controls.commercialNo.setValue(this.data.record.commercialIdentificationNumber);
    this.formGroup.controls.issuingDate.setValue(this.data.record.accreditationCommencementDate);
    this.formGroup.controls.expirationDate.setValue(this.data.record.certificationExpirationDate);
    this.formGroup.controls.email.setValue(this.data.record.email);
    this.formGroup.controls.country.setValue(this.data.record.countryId);
    this.formGroup.controls.city.setValue(this.data.record.cityId);
    this.formGroup.controls.enAddress.setValue(this.data.record.englishAddress);
    this.formGroup.controls.arAddress.setValue(this.data.record.arabicAddress);
  }
  checkInScopesSource(apTypes, scopes) {
    this.scopesSelection.clear();
    apTypes.forEach(scope => {
      const scopeFound = this.scopesDataSource['_data'].find(item => scope.id === item.id && scope.text === item.text);
      if (scopeFound) {
        this.scopesSelection.select(scopeFound);
      }
    });
    scopes.forEach(scope => {
      this.scopesDataSource['_data'].forEach(parent => {
        if (parent.children && parent.children.length) {
          const scopeFound = parent.children.find(item => scope.id === item.id && scope.text === item.text);
          if (scopeFound) {
            this.scopesSelection.select(scopeFound);
          }
        }
      });
    });
  }

  saveRecored() {
    this.validateScopes();
    this.isSubmitted = true;
    if (this.formGroup.valid && this.logoSrc) {
      this.loading = true;
      let formData;
      formData = {
        arabicName: this.formGroup.controls.arName.value,
        englishName: this.formGroup.controls.enName.value,
        commercialIdentificationNumber: this.formGroup.controls.commercialNo.value,
        accreditationCommencementDate: this.formGroup.controls.issuingDate.value.toLocaleString(),
        certificationExpirationDate: this.formGroup.controls.expirationDate.value.toLocaleString(),
        ABTypeIds: [],
        scopeIds: [],
        email: this.formGroup.controls.email.value,
        countryId: this.formGroup.controls.country.value,
        cityId: this.formGroup.controls.city.value,
        englishAddress: this.formGroup.controls.enAddress.value,
        arabicAddress: this.formGroup.controls.arAddress.value
      } as CAB;
      this.selectedScopes.forEach((element: Scope) => {
        if (!element.childCount) {
          if (element.parentId <= 0) {
            if (!this.isInArray(formData.ABTypeIds, element)) {
              formData.ABTypeIds.push(element.id);
            }
          } else {
            if (!this.isInArray(formData.scopeIds, element)) {
              formData.scopeIds.push(element.id);
            }
          }
        }
      });
      if (!this.isCreate) {
        formData.entityId = this.data.record.entityId;
      }
      this.service.save(formData, this.logo).subscribe(
        () => {
          this.loading = false;
          Swal.fire({
            title: this.translate.instant('messages.Success'),
            text: (this.isCreate) ?
              this.translate.instant('CABs.CAB created successfully') : this.translate.instant('CABs.CAB updated successfully'),
            icon: 'success',
            confirmButtonText: this.translate.instant('messages.ok')
          });
          this.dialogRef.close(true);
        }, err => {
          this.loading = false;
          console.error(err);
          Swal.fire({
            title: this.translate.instant('messages.Error'),
            text: err.message,
            icon: 'error',
            confirmButtonText: this.translate.instant('messages.ok')
          });
          // this.dialogRef.close();
        });
    }
  }

  // File preview with validation
  changeFileListener($event): void {
    const file: File = $event.target.files[0];
    if (file && this.validateFile(file)) {
      this.readThis(file);
      this.logo = file;
    } else {
      this.logoSrc = undefined;
      this.logo = undefined;
    }
  }
  readThis(file: File): void {
    const myReader: FileReader = new FileReader();
    myReader.readAsDataURL(file);
    myReader.onloadend = (e) => {
      this.logoSrc = myReader.result as string;
    };
  }
  validateFile(file: File): any {
    if (this.fileType(file.name)) {
      this.fileTypeError = false;
      if ((file.size / (1024 * 1024)) <= this.maxFileSize) {
        this.fileSizeError = false;
      } else {
        this.fileSizeError = true;
        return false;
      }
    } else {
      this.fileTypeError = true;
      return false;
    }
    return true;
  }
  fileType(fileName): any {
    const extension = fileName.split('.').pop().toLowerCase();
    switch (extension) {
      case 'jpeg':
      case 'jpg':
        return 'jpg';
      case 'png':
        return 'png';
      default:
        return false;
    }
  }

  isInArray(parentObj, item) {
    return parentObj.some(obj => obj.id === item.id);
  }

  validateScopes() {
    if (this.selectedScopes.length) {
      this.formGroup.get('scopes').setErrors(null);
    } else {
      this.formGroup.get('scopes').setErrors({required: true});
    }
  }
  // Chips, autocomplete fields methods
  handelChipsFilters() {
    this.filteredScopes = this.formGroup.controls.scopes.valueChanges.pipe(
      startWith(''),
      map(scope => scope ? this._filter(scope, this.SCOPE_TREE_DATA, 'scopes') : this.SCOPE_TREE_DATA.slice()));
  }

  private _filter(filteritem: any, parentObj, control: string) {
    if (typeof filteritem === 'string' || filteritem instanceof String) {
      const matches = parentObj.filter(obj => obj.name.toLowerCase().includes(filteritem.toLowerCase()));
      if (filteritem !== '') {
        this.formGroup.get(control).setErrors({valid: false});
      }
      return matches;
    } else if (filteritem instanceof Object) { }
  }
  // TREE configrations //////////////////////////////////////////
  /** Whether all the descendants of the node are selected */
  descendantsAllSelected(node: Scope): boolean {
    const descendants = this.scopesTreeControl.getDescendants(node);
    return descendants.every(child => this.scopesSelection.isSelected(child));
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: Scope): boolean {
    const descendants = this.scopesTreeControl.getDescendants(node);
    const result = descendants.some(child => this.scopesSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: Scope): void {
    this.scopesSelection.toggle(node);
    const descendants = this.scopesTreeControl.getDescendants(node);
    this.scopesSelection.isSelected(node)
      ? this.scopesSelection.select(...descendants)
      : this.scopesSelection.deselect(...descendants);
  }

  selectedScope(node) {
    if (this.scopesSelection.isSelected(node)) {
      this.scopesSelection.selected.forEach((element: Scope) => {
        if ((element.parentId > 0 || !element.childCount) && !this.isInArray(this.selectedScopes, element)) {
          this.selectedScopes.push(element);
        }
      });
    } else {
      this.removeNode(node);
    }
    // this.checkInScopesSource(this.selectedScopes, this.selectedScopes);
    this.validateScopes();
  }

  removeNode(node: Scope) {
    if (node.parentId <= 0 && node.childCount > 0) {
      node.children.forEach(element => {
        this.removeNode(element);
      });
    }
    const index = this.selectedScopes.findIndex(item => (item.id === node.id && item.parentId === node.parentId));
    if (index >= 0) {
      this.selectedScopes.splice(index, 1);
    }
    this.checkInScopesSource(this.selectedScopes, this.selectedScopes);
    this.validateScopes();
  }
  // END TREE configrations //////////////////////////////////////////


  // dropdowns overlay events
  @HostListener('window:keydown', ['$event']) handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.showScopesTree = false;
    }
  }
  @HostListener('document:click', ['$event']) onDocumentClick(event: MouseEvent) {
    if (event.target === document.getElementById('popOverlay')) {
      this.showScopesTree = false;
    }
  }
}
