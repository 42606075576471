import { Component, OnInit } from '@angular/core';

import { CabsFormComponent } from './cabs-form/cabs-form.component';
import { ListFilter } from 'src/app/models/cabs/ListFilter';
import { ListData } from 'src/app/models/cabs/ListData';
import { sortList } from 'src/app/models/cabs/sortList';
import { statusList } from 'src/app/models/cabs/statusList';
import { CabsService } from 'src/app/services/cabs.service';

import { MatDialog } from '@angular/material/dialog';
import { LookupsService } from 'src/app/services/lookups.service';
import { Country } from 'src/app/models/lookups/Country';
import { City } from 'src/app/models/lookups/City';

import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-cabs',
  templateUrl: './cabs.component.html'
})
export class CABSComponent implements OnInit {
  dataLoading = false;
  showFilter = false;
  showSearch = false;

  sortItems = [
    { name: '', value: null },
    { name: this.translate.instant('general.Name'), value: sortList.Name },
    { name: this.translate.instant('general.Country'), value: sortList.Country },
    { name: this.translate.instant('general.Status'), value: sortList.Status },
  ];
  statusList = [
    { name: this.translate.instant('CABs.WaitingForPayment'), value: statusList.WaitingForPayment },
    { name: this.translate.instant('CABs.WaitingPaymentApproval'), value: statusList.WaitingPaymentApproval },
    { name: this.translate.instant('general.Active'), value: statusList.Active },
    { name: this.translate.instant('general.InActive'), value: statusList.InActive },
    { name: this.translate.instant('general.Suspended'), value: statusList.Suspended },
    { name: this.translate.instant('general.Expired'), value: statusList.Expired },
    { name: this.translate.instant('general.Withdrawn'), value: statusList.Withdrawn }
  ];

  // table
  pageCount = 0;
  manualPage = 0;
  recordsData: any = {};

  filterModel: ListFilter = new ListFilter();
  countries: Country[] = [];
  cities: City[] = [];

  constructor(
    public dialog: MatDialog,
    private service: CabsService,
    private lookupsService: LookupsService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.filterModel.pageSize = 10;
    this.getAll(null);
    this.getCountries();
  }

  getAll(event) {
    this.dataLoading = true;
    if (event) {
      this.filterModel.pageNumber = event.pageIndex;
    } else {
      this.filterModel.pageNumber = 0;
    }
    this.service.getAll(this.filterModel).subscribe(
      data => {
        this.dataLoading = false;
        if (data) {
          this.recordsData = data as ListData;
          this.pageCount = Math.ceil(data.totalCount / data.pageSize);
          this.manualPage = Number(data.pageIndex) + 1;
        }
      },
      error => {
        this.dataLoading = false;
    });
  }

  getCountries() {
    this.lookupsService.getCountries().subscribe(
      data => {
        this.countries = data as Country[];
      }, err => {
        console.error(err);
      });
  }
  getCities(countryId) {
    this.lookupsService.getCities(countryId).subscribe(
      data => {
        this.cities = data as City[];
      }, err => {
        console.error(err);
      });
  }

  sortRecords(selected) {
    this.filterModel.sortItems = Number(selected.target.value);
    this.getAll(null);
  }

  // SEARCH
  makeSearch() {
    if (!this.showSearch) {
      this.showSearch = true; // Show input
    } else {
      if (this.filterModel.searchText) {
        this.getAll(null);
      }
    }
  }
  makeFilter() {
    this.getAll(null);
  }
  resetAfterClear() {
    if (!this.filterModel.searchText) {
      this.resetSearch();
    }
  }
  resetSearch() {
    this.filterModel.searchText = '';
    this.getAll(null);
  }

  updatePage(event) {
    if (event.target.value <= this.pageCount) {
      this.filterModel.pageNumber = event.target.value - 1;
      this.getAll(null);
    }
  }

  initName(name) {
    if (!name) { return ''; }
    let initials = name.match(/\b\w/g) || [];
    initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
    return initials;
  }

  openCreateDialog() {
    const dialogRef = this.dialog.open(CabsFormComponent, {
      width: '600px',
      data: {record: null}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getAll(null);
      }
    });
  }

}
