import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AsyncValidatorFn, AbstractControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { EntityFormComponent } from 'src/app/features/entities/entity-form/entity-form.component';
import { CertificateService } from 'src/app/services/certificate.service';
import { EntityService } from 'src/app/services/entity.service';
import { LookupsService } from 'src/app/services/lookups.service';

import { Entity } from 'src/app/models/entity/Entity';
import { Certificate } from 'src/app/models/certificates/Certificate';

import { MatDialog } from '@angular/material/dialog';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MatChipList } from '@angular/material/chips';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

export interface DialogData {
  create: boolean;
  record: any;
}

@Component({
  selector: 'app-create-certificates',
  templateUrl: './create-certificates.component.html'
})
export class CreateCertificatesComponent implements OnInit {
  entityLoader = false;
  entityError = false;
  loading = false;
  branchesLoading = false;
  repsentativesLoading = false;
  certificateNumLoader = false;

  details: any;
  formGroup: FormGroup;
  isSubmitted = false;
  selectedEntity: Entity = new Entity();
  branches: any;
  repsentatives: any;
  files = [];
  entities = [];

  today = new Date();

  //////// Scopes field config /////////////////
  allABTypes = [];
  scopeLoading = false;
  filteredScopes: Observable<any[]>;
  selectedScopes: {}[] = [];
  allScopes: any[] = [];
  @ViewChild('scopeInput', {static: false}) scopeInput: ElementRef<HTMLInputElement>;
  @ViewChild('scopeAuto', {static: false}) scopeMatAutocomplete: MatAutocomplete;
  @ViewChild('scopesChipList', {static: false}) scopesChipList: MatChipList;
  scopeChipsErrorMsg: string;
  ////////// End Scopes field config //////////////

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<CreateCertificatesComponent>,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private lookupsService: LookupsService,
    private service: CertificateService,
    private entityService: EntityService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.buildForms();
  }

  buildForms() {
    this.formGroup = this.formBuilder.group({
      commerialId: ['', [Validators.required]], // this.selectedEntity.certifiedEntityId
      ABTypeId: ['', Validators.required],
      // scopes: [this.selectedScopes],
      scopes: [null, Validators.required],
      certificateNo: ['', Validators.required, [this.uniqueCertificateNoValidator()]],
      issuingDate: ['', Validators.required],
      expirationDate: ['', Validators.required],
      authorizedOfficerId: ['', Validators.required],
      entityBranch: [''],
    });
    if (!this.data.create) {
      this.details = JSON.parse(JSON.stringify(this.data.record));
      if (this.details.certificateDocument) {
        this.files = [this.details.certificateDocument];
      }
      this.formGroup.controls.commerialId.setValue(this.details.certifiedEntity.commercialIdentificationNumber);
      this.formGroup.controls.ABTypeId.setValue(this.details.abTypeId);
      this.formGroup.controls.scopes.setValue(this.details.scopes[0].id);
      this.formGroup.controls.certificateNo.setValue(this.details.certificateNumber);
      this.formGroup.controls.issuingDate.setValue(this.details.issueingDate);
      this.formGroup.controls.expirationDate.setValue(this.details.expirationDate);
      this.getEntityByc(); // authorizedOfficerId & entityBranch depend on entity
    }
    this.getEntities();
    this.getAbTypes();

    // this.formGroup.get('scopes').statusChanges.subscribe(
    //   status => {
    //     // if (this.allScopes.length && !this.selectedScopes.length) {
    //     //   this.scopesChipList.errorState = status === 'INVALID';
    //     // }
    //     // console.log(this.formGroup.controls.scopes.value);
    //   }
    // );
    // this.filteredScopes = this.formGroup.controls.scopes.valueChanges.pipe(
    //   startWith(null),
    //   map(scope => scope ? this._filter(scope) : this.allScopes.slice()));
  }
  hasError(formGroup, controlName: string, errorName: string) {
    return formGroup.controls[controlName].hasError(errorName);
  }
  // private _filter(filteritem: any) {
  //   if (typeof filteritem === 'string' || filteritem instanceof String) {
  //     const matches = this.allScopes.filter(obj => obj.text.toLowerCase().includes(filteritem.toLowerCase()));
  //     if (filteritem !== '') {
  //       this.formGroup.get('scopes').setErrors({valid: false});
  //     }
  //     return matches;
  //   } else if (filteritem instanceof Object) { }
  // }

  getEntities() {
    this.entityLoader = true;
    const filter = {
      pageSize: 999
    };
    this.entityService.getAll(filter).subscribe(
      data => {
        this.entityLoader = false;
        if (data) {
          this.entities = data.pageData;
        }
      },
      error => {
        this.entityLoader = false;
    });
  }
  entityChanged(commerialId) {
    this.selectedEntity = this.entities.find(entity => entity.commercialIdentificationNumber === commerialId);
    if (this.selectedEntity.certifiedEntityId) {
      this.getBranches();
      this.getRepsentatives();
    }
  }
  getAbTypes() {
    this.scopeLoading = true;
    this.lookupsService.GetAbTypes(null).subscribe(
      data => {
        this.scopeLoading = false;
        this.allABTypes = data as [];
        if (!this.data.create) {
          // this.selectedScopes = this.data.record.scopes || [];
          this.allScopes = this.allABTypes.find(type => type.id === this.formGroup.controls.ABTypeId.value).otherChildren as [];
          // const selectedScope = {
          //   id: this.details.scopes[0].id,
          //   text: this.details.scopes[0].text,
          //   nameAr: this.details.scopes[0].nameAr,
          //   nameEn: this.details.scopes[0].nameEn,
          //   parentId: this.details.scopes[0].parentId
          // };
          // this.formGroup.controls.scopes.setValue(selectedScope);
        }
      }, err => {
        console.error(err);
      });
  }
  updateScops() {
    // this.selectedScopes = []; // reset chips
    this.formGroup.controls.scopes.setValue(null);
    const ABType = this.allABTypes.find(type => type.id === this.formGroup.controls.ABTypeId.value);
    this.allScopes = ABType.otherChildren as [];
  }
  getEntityByc() {
    if (this.formGroup.controls.commerialId.value) {
      this.entityLoader = true;
      this.service.getEntityByCiNumber(this.formGroup.controls.commerialId.value).subscribe(
        data => {
          this.entityLoader = false;
          this.selectedEntity = data as Entity;
          if (this.selectedEntity.certifiedEntityId) {
            this.getBranches();
            this.getRepsentatives();
            this.formGroup.controls.commerialId.setErrors(null);
          }
        }, err => {
          console.error(err);
          this.entityLoader = false;
          this.formGroup.controls.commerialId.setErrors({ invalidCommerialId : true });
        });
    }
  }
  getBranches() {
    this.branches = [];
    this.branchesLoading = true;
    this.formGroup.controls.entityBranch.setValue(null);
    this.entityService.ListCertifiedEntityBranches(this.selectedEntity.certifiedEntityId).subscribe(
      data => {
        this.branchesLoading = false;
        if (data) {
          this.branches = data.pageData;
          if (!this.data.create && this.data.record.branchNames.length) {
            this.formGroup.controls.entityBranch.setValue(this.data.record.branchNames[0].id);
          }
        }
      },
      error => {
        this.branchesLoading = false;
    });
  }
  getRepsentatives() {
    this.repsentatives = [];
    this.repsentativesLoading = true;
    this.formGroup.controls.authorizedOfficerId.setValue(null);
    this.entityService.ListCertifiedEntityRepsentative(this.selectedEntity.certifiedEntityId).subscribe(
      data => {
        this.repsentativesLoading = false;
        if (data) {
          this.repsentatives = data.pageData;
          if (!this.data.create) {
            this.formGroup.controls.authorizedOfficerId.setValue(this.data.record.authorizedOfficerId);
          }
        }
      },
      error => {
        this.repsentativesLoading = false;
    });
  }
  uniqueCertificateNoValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<{ [key: string]: any } | null> => {
      if (control.value) { this.certificateNumLoader = true; }
      return this.service.checkUniqueCertificateNo(control.value)
      .pipe(
        map(res => {
          this.certificateNumLoader = false;
          if (!this.data.create) { return; }
          if (res) {
            return { certificateNoExist: true };
          }
        })
      );
    };
  }
  saveRecored() {
    // this.validateScopes();
    this.isSubmitted = true;
    if (this.formGroup.valid && this.files.length && !this.repsentativesLoading) {
      this.loading = true;
      let recored;
      recored = {
        certifiedEntityId: this.selectedEntity.certifiedEntityId,
        ABTypeId: this.formGroup.controls.ABTypeId.value,
        // scopes: this.selectedScopes,
        scopes: [this.allScopes.find(scope => scope.id === this.formGroup.controls.scopes.value)],
        certificateNumber: this.formGroup.controls.certificateNo.value,
        issuingDate: this.formGroup.controls.issuingDate.value.toLocaleString(),
        expirationDate: this.formGroup.controls.expirationDate.value.toLocaleString(),
        authorizedOfficerId: this.formGroup.controls.authorizedOfficerId.value,
        ceBranchIds: this.formGroup.controls.entityBranch.value ? [this.formGroup.controls.entityBranch.value] : [],
      } as Certificate;
      if (!this.data.create) {
        recored.certificateId = this.data.record.certificateId;
      }
      this.service.save(recored, this.files).subscribe(
        data => {
          this.loading = false;
          this.dialogRef.close(true);
        }, err => {
          this.loading = false;
          Swal.fire({
            title: this.translate.instant('messages.Error'),
            text: err.message,
            icon: 'error',
            confirmButtonText: this.translate.instant('messages.ok')
          });
        });
    }
  }

  openCreateEntiryDialog() {
    const dialogRef = this.dialog.open(EntityFormComponent, {
      width: '600px'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        Swal.fire({
          title: this.translate.instant('messages.Success'),
          text: this.translate.instant('messages.Entity created successfully'),
          icon: 'success',
          confirmButtonText: this.translate.instant('messages.ok')
        });
      }
    });
  }

  isInArray(parentObj, item) {
    return parentObj.some(obj => obj.id === item.id);
  }

  selected(event): void {
    if (!this.isInArray(this.selectedScopes, event.option.value)) {
      this.selectedScopes.push(event.option.value);
      this.scopeInput.nativeElement.value = '';
      this.formGroup.controls.scopes.setValue(null);
    }
    this.validateScopes();
  }

  removeScope(scope): void {
    const index = this.selectedScopes.indexOf(scope);
    if (index >= 0) {
      this.selectedScopes.splice(index, 1);
    }
    this.validateScopes();
  }

  validateScopes() {
    if (this.allScopes.length) {
      if (!this.selectedScopes.length) {
        this.formGroup.get('scopes').setErrors({valid: true});
      } else {
        this.formGroup.get('scopes').setErrors(null);
      }
    } else {
      this.scopesChipList.errorState = status === 'VALID';
      this.formGroup.get('scopes').setErrors(null);
    }
  }
}
