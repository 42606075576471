import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, AbstractControl, AsyncValidatorFn } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthenticationService } from 'src/app/services/authentication.service';
import { LookupsService } from 'src/app/services/lookups.service';
import { TranslateService } from '@ngx-translate/core';
import { AnnouncementsService } from 'src/app/services/announcements.service';

import { EntitiesByCountry } from 'src/app/models/Announcements/EntitiesByCountry';
import { Register } from 'src/app/models/auth/Register';
import { City } from 'src/app/models/lookups/City';
import { Country } from 'src/app/models/lookups/Country';

import { NestedTreeControl } from '@angular/cdk/tree';
import { ArrayDataSource, SelectionModel } from '@angular/cdk/collections';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html'
})
export class RegisterComponent implements OnInit {

  @ViewChild('form', { static: false }) form;

  loading = false;
  isSubmitted = false;

  formGroup: FormGroup;

  countries: Country[] = [];
  cities: City[] = [];
  Nationalities: Country[] = [];

  captcha: string;
  hidePassword = true;
  hideConfPassword = true;

  emailLoader = false;

  //////// Sectors field config /////////////////
  showSectoreTree = false;
  sectorLoading = false;
  selectedSector = {} as EntitiesByCountry;
  sectors = [];
  /** The selection for checklist */
  sectorsSelection = new SelectionModel<EntitiesByCountry>(true /* multiple */);
  SECTOR_TREE_DATA: EntitiesByCountry[] = [];
  sectorTreeData: any[];
  sectorsTreeControl = new NestedTreeControl<EntitiesByCountry>(node => node.entities);
  sectorsDataSource = new ArrayDataSource(this.SECTOR_TREE_DATA);
  hasChild = (_: number, node: EntitiesByCountry) => !!node.entities && node.entities.length > 0;

  constructor(
    private formBuilder: FormBuilder,
    private lookupsService: LookupsService,
    private translate: TranslateService,
    private service: AuthenticationService,
    private serviceAnn: AnnouncementsService,
  ) { }


  ngOnInit() {
    this.LoadEntitiesByCountry();
    this.buildForm();
  }

  LoadEntitiesByCountry() {
    this.sectorLoading = true;
    this.serviceAnn.LoadEntitiesByCountry().subscribe(
      data => {
        this.sectorLoading = false;
        this.SECTOR_TREE_DATA = data as EntitiesByCountry[];
        // // console.log(data);
        this.sectorsDataSource = new ArrayDataSource(this.SECTOR_TREE_DATA);
      }, err => {
        console.error(err);
      });
  }


  buildForm() {
    this.formGroup = this.formBuilder.group({
      enName: ['', [
        Validators.required,
        Validators.pattern('^[0-9a-zA-Z ]+$')
      ]
      ],
      arName: ['', [Validators.pattern('^(?=.*[\u0600-\u06FF])[\u0600-\u06FF0-9 _-]+$')]],
      email: ['', [
          Validators.email,
          Validators.pattern('[a-zA-Z0-9._]{1,}@[a-zA-Z0-9.-]{2,}[.]{1}[a-zA-Z]{2,}'),
          Validators.required
        ], [this.uniqueEmailValidator()]
      ],
      country: ['', Validators.required],
      city: ['', Validators.required],
      nationality: ['', Validators.required],
      mobile: ['', [
        Validators.required,
        Validators.pattern('^[+]?[0-9]{11,50}$')
      ]
      ],
      SpecializationIds: [this.sectors, this.validateChips],
      password: ['', [
        Validators.required,
        Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?#&._-])[A-Za-z\d$@$!%*?#&].{7,}$')
      ]],
      cPassword: ['', Validators.required]
    }, {validator: this.validateMatch });

    this.getCountries();
    this.GetNationalities();
  }

  uniqueEmailValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<{ [key: string]: any } | null> => {
      if (control.value) { this.emailLoader = true; }
      return this.service.checkUniqueEmail(control.value)
        .pipe(
          map(res => {
            this.emailLoader = false;
            // if (this.isUpdateUser) { return; }
            if (res) {
              return { emailTaken: true };
            }
          })
        );
    };
  }

  validateMatch(group: FormGroup) {
    const pass = group.get('password').value;
    const confirmPass = group.get('cPassword').value;
    if (pass !== confirmPass) {
      group.get('cPassword').setErrors({ notMatch: true });
    } else {
      group.get('cPassword').setErrors(null);
    }
  }


  getCountries() {
    this.lookupsService.getCountries().subscribe(
      data => {
        this.countries = data as Country[];
      }, err => {
        console.error(err);
      });
  }

  GetNationalities() {
    this.lookupsService.GetNationalities().subscribe(
      data => {
        this.Nationalities = data as Country[];
      }, err => {
        console.error(err);
      });
  }

  getCities(countryId) {
    this.lookupsService.getCities(countryId).subscribe(
      data => {
        this.cities = data as City[];
      }, err => {
        console.error(err);
      });
  }


  saveRecored() {
    if (this.sectors.length === 0 && this.formGroup.get('SpecializationIds').value) {
      this.formGroup.get('SpecializationIds').setErrors({ notValid: true });
    }

    this.isSubmitted = true;

    if (this.formGroup.valid && this.captcha) {
      this.loading = true;
      let formData;
      formData = {
        arabicFullName: this.formGroup.controls.arName.value,
        englishFullName: this.formGroup.controls.enName.value,
        email: this.formGroup.controls.email.value,
        phone: this.formGroup.controls.mobile.value,
        cityid: this.formGroup.controls.city.value,
        nationalityId: this.formGroup.controls.nationality.value,
        entityId: this.selectedSector.id,
        password: this.formGroup.controls.password.value
      } as Register;

      this.service.RegisterEntityUser(formData).subscribe(
        () => {
          this.loading = false;
          Swal.fire({
            title: this.translate.instant('messages.Success'),
            text: this.translate.instant('auth.YourRequestSubmittedSuccessfully'),
            icon: 'success',
            confirmButtonText: this.translate.instant('messages.ok')
          }).then(() => {
            this.form.resetForm();
         });
        }, err => {
          this.loading = false;
          console.error(err);
          Swal.fire({
            title: this.translate.instant('messages.Error'),
            text: err.message,
            icon: 'error',
            confirmButtonText: this.translate.instant('messages.ok')
          });
        });
    }
  }

  public resolved(captchaResponse: string) {
    this.captcha = captchaResponse;
    // // console.log(`Resolved captcha with response: ${captchaResponse}`);
  }

  hasError(formGroup, controlName: string, errorName: string) {
    return formGroup.controls[controlName].hasError(errorName);
  }


  selectSector(node) {
    // // console.log(node);
    this.selectedSector = node;
    this.sectors = [this.selectedSector];
    this.formGroup.controls.SpecializationIds.setValue(node.name);
  }

  remove(obj, parentObj, control): void {
    const index = parentObj.indexOf(obj);
    if (index >= 0) {
      parentObj.splice(index, 1);
      this.formGroup.get(control).setValue(parentObj);
    }
  }

  isInArray(parentObj, item) {
    return parentObj.some(obj => obj.id === item.id);
  }

  private validateChips(control: FormControl) {
    if (control.value && control.value.length === 0) {
      return {
        validateChipsArray: { valid: false }
      };
    }

    return null;
  }
  // TREE   configrations //////////////////////////////////////////
  /** Whether all the descendants of the node are selected */
  descendantsAllSelected(node: EntitiesByCountry): boolean {
    const descendants = this.sectorsTreeControl.getDescendants(node);
    return descendants.every(child => this.sectorsSelection.isSelected(child));
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: EntitiesByCountry): boolean {
    const descendants = this.sectorsTreeControl.getDescendants(node);
    const result = descendants.some(child => this.sectorsSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: EntitiesByCountry): void {
    this.sectorsSelection.toggle(node);
    const descendants = this.sectorsTreeControl.getDescendants(node);
    this.sectorsSelection.isSelected(node)
      ? this.sectorsSelection.select(...descendants)
      : this.sectorsSelection.deselect(...descendants);
  }


  removeSector(obj): void {
    const index = this.sectors.indexOf(obj);
    if (index >= 0) {
      this.sectors.splice(index, 1);
      this.todoItemSelectionToggle(obj);
    }
  }
  // END TREE   configrations //////////////////////////////////////////


  // dropdowns overlay events
  @HostListener('window:keydown', ['$event']) handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.showSectoreTree = false;
    }
  }
  @HostListener('document:click', ['$event']) onDocumentClick(event: MouseEvent) {
    if (event.target === document.getElementById('popOverlay')) {
      this.showSectoreTree = false;
    }
  }

}
