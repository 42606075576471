import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html'
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordForm: FormGroup;

  loading = false;
  msgObj = {msg: '', type: ''};
  urlExpired = false;
  hidePassword = true;
  hideConfPassword = true;

  userId: string;
  code: string;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private translate: TranslateService
    ) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.userId = params.get('userid');
      this.code = params.get('code');
    });

    this.authenticationService.checkResetPasswordLink(this.userId, this.code).subscribe(
      data => {
        if (!data) {
          this.urlExpired = true;
        }
      },
      error => {

      }
    );

    this.resetPasswordForm = this.formBuilder.group({
      password: ['', [
        Validators.required,
        Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?#&._-])[A-Za-z\d$@$!%*?#&].{7,}$')
      ]],
      cPassword: ['', Validators.required]
    }, {validator: this.validateMatch });
  }

  resetPassword() {
    if (this.resetPasswordForm.valid) {
      this.loading = true;
      this.authenticationService.resetPassword(this.userId, this.code, this.resetPasswordForm.controls.password.value).subscribe(
        data => {
          this.loading = false;
          this.msgObj.msg = this.translate.instant('auth.Password-updated-successfully');
          this.msgObj.type = 'success';
          Swal.fire({
            title: this.translate.instant('messages.Success'),
            text: '',
            icon: 'success',
            confirmButtonText: this.translate.instant('messages.ok')
          }).then((result) => {
            this.router.navigate(['/logout']);
          });
        },
        error => {
          this.loading = false;
          this.msgObj.msg = this.translate.instant('auth.Reset-expire');
          this.msgObj.type = 'danger';
          // this.urlExpired = true;
        });
    }
  }

  validateMatch(group: FormGroup) {
    const pass = group.get('password').value;
    const confirmPass = group.get('cPassword').value;
    if (pass !== confirmPass) {
      group.get('cPassword').setErrors({ notMatch: true });
    } else {
      group.get('cPassword').setErrors(null);
    }
  }

  hasError(controlName: string, errorName: string) {
    return this.resetPasswordForm.controls[controlName].hasError(errorName);
  }

}
