import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { LoginModel } from 'src/app/models/auth/login-model';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loginObject: LoginModel = new LoginModel();

  loading = false;
  hidePassword = true;
  rememberMe = false;

  returnUrl: string;

  errorMsg = '';
  captcha: string;
  formSubmitted = false;
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
    if (this.route.snapshot.routeConfig.path === 'logout') {
      this.authenticationService.logout();
      this.router.navigate(['/login']);
      return;
    }
    // redirect to home if already logged in
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/admin/myprofile']);
    }
  }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/admin/myprofile';

    this.loginForm = this.formBuilder.group({
      email: new FormControl('', [
        Validators.required,
        Validators.email,
        Validators.pattern('[a-zA-Z0-9._]{1,}@[a-zA-Z0-9.-]{2,}[.]{1}[a-zA-Z]{2,}'),
      ]),
      password: ['', Validators.required],
    });
  }

  login() {
    this.formSubmitted = true;
    if (this.loginForm.valid && this.captcha) {
      this.loading = true;
      this.authenticationService.login(this.loginForm.controls.email.value, encodeURIComponent(this.loginForm.controls.password.value))
        .subscribe(
          data => {
            this.loading = false;
            // this.router.navigate([this.returnUrl]);
          },
          error => {
            this.errorMsg = error.error_description;
            this.loading = false;
          });
    }
  }

  public resolved(captchaResponse: string) {
    this.captcha = captchaResponse;
    // // console.log(`Resolved captcha with response: ${captchaResponse}`);
  }

  hasError(controlName: string, errorName: string) {
    return this.loginForm.controls[controlName].hasError(errorName);
  }
}
