import { Component, Inject, OnInit } from '@angular/core';
import { CabsService } from 'src/app/services/cabs.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

export interface DialogData {
  supposedAmount: any;
  duePaymentIds: [];
  transactionType: number;
}

@Component({
  selector: 'app-cab-payment',
  templateUrl: './cab-payment.component.html'
})
export class CabPaymentComponent implements OnInit {
  loading = false;
  files = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<CabPaymentComponent>,
    private translate: TranslateService,
    private service: CabsService
  ) { }

  ngOnInit() {
  }

  save() {
    this.loading = true;
    const obj = {
      duePaymentIds: this.data.duePaymentIds,
      transactionType: this.data.transactionType,
      paymentMethod: 1,
      totalPaymentAmount: 0
    };
    this.service.SubmitPayment(obj, this.files[0]).subscribe(
      data => {
        this.loading = false;
        Swal.fire({
          title: this.translate.instant('messages.Success'),
          text: this.translate.instant('messages.Payment submitted successfully'),
          icon: 'success',
          confirmButtonText: this.translate.instant('messages.ok')
        });
        this.dialogRef.close(true);
      }, err => {
        this.loading = false;
        console.error(err);
      });
  }

}
