import { Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2';

import { CreateCertificatesComponent } from 'src/app/features/certificates/create-certificates/create-certificates.component';
import { CabsService } from 'src/app/services/cabs.service';
import { CabsFormComponent } from '../cabs-form/cabs-form.component';
import { RenewExpirationDialogComponent } from './renew-expiration-dialog/renew-expiration-dialog.component';
import { WalletTransDialogComponent } from '../wallet-trans-dialog/wallet-trans-dialog.component';
import { CabPaymentComponent } from './cab-payment/cab-payment.component';
import { CreateUserComponent } from 'src/app/features/users/create-user/create-user.component';
import { TranslateService } from '@ngx-translate/core';
import { ChooseInvoicesComponent } from 'src/app/features/due-payments/choose-invoices/choose-invoices.component';
import { SignalRServiceService } from 'src/app/services/signal-rservice.service';
import { EntityService } from 'src/app/services/entity.service';

@Component({
  selector: 'app-cabdetails',
  templateUrl: './cabdetails.component.html'
})
export class CABDetailsComponent implements OnInit {
  dataLoading = true;
  actionLoading = false;
  cabId: any;
  details: any;
  isCabProfile = false;

  representativesLoader = true;
  representatives: any;

  duePayments = [];

  entitiesLoading = true;
  certifiedEntities: any;

  constructor(
    public zone: NgZone,
    private translate: TranslateService,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private signalRService: SignalRServiceService,
    private entityService: EntityService,
    private service: CabsService
  ) {
    this.signalRService.handleRespondPaymentEvent(this, this.updateDetails);
    this.signalRService.handleUpdateCBProfileEvent(this, this.updateDetails);
  }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe(params => {
      if (params.get('id')) {
        this.cabId = params.get('id');
      }
      this.getDetails();
    });
  }

  updateDetails(context, id) {
    context.zone.run(() => {
      if (!context.dataLoading) {
        if (context.details.entityId === id) {
          context.getDetails();
        }
      }
    });
  }

  getDetails() {
    this.dataLoading = true;
    if (this.cabId) {
      this.service.GetEntitiesDetails(this.cabId).subscribe(
        data => {
          this.dataLoading = false;
          this.details = data;
          this.GetEntityUsersRepresentatives();
          this.ListCertifiedEntities();
        }, err => {
          console.error(err);
        });
    } else {
      this.service.GetEntityProfileDetails().subscribe(
        data => {
          this.dataLoading = false;
          this.details = data;
          this.isCabProfile = true;
        }, err => {
          console.error(err);
          this.dataLoading = false;
          this.details = null;
        });
    }
    this.loadDuePayments();
  }

  loadDuePayments() {
    this.service.GetDuePaymentIds().subscribe(
      data => {
        this.duePayments = data as [];
      }, err => {
        console.error(err);
      });
  }

  GetEntityUsersRepresentatives() {
    this.service.GetEntityUsersRepresentatives(this.cabId).subscribe(
      data => {
        this.representativesLoader = false;
        this.representatives = data;
        // console.log(data);
      }, err => {
        console.error(err);
      });
  }

  ListCertifiedEntities() {
    this.entityService.getAll({pageSize: 999, cabEntityId: this.cabId}).subscribe(
      data => {
        this.entitiesLoading = false;
        if (data) {
          this.certifiedEntities = data as any;
        }
      },
      error => {
        this.dataLoading = false;
    });
  }

  changeStatus(status) {
    Swal.fire({
      title: this.translate.instant('messages.Are you sure?'),
      text: '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('general.yes'),
      cancelButtonText: this.translate.instant('general.Cancel')
    }).then((result) => {
      if (result.value) {
        this.actionLoading = true;
        const obj = {
          id: this.cabId,
          entityStatus: status
        };
        this.service.UpdateEntityStatus(obj).subscribe(
          data => {
            this.actionLoading = false;
            this.getDetails();
            Swal.fire({
              title: this.translate.instant('messages.Success'),
              text: this.translate.instant('CABs.CAB updated successfully'),
              icon: 'success',
              confirmButtonText: this.translate.instant('messages.ok')
            });
          }, err => {
            this.actionLoading = false;
            console.error(err);
          });
      }
    });
  }
  renewAccreditation() {
    const dialogRef = this.dialog.open(RenewExpirationDialogComponent, {
      width: '600px',
      data: {record: this.details}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getDetails();
      }
    });
  }
  representativeStatus(representative, status) {
    Swal.fire({
      title: this.translate.instant('messages.Are you sure?'),
      text: '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('general.yes'),
      cancelButtonText: this.translate.instant('general.Cancel')
    }).then((result) => {
      if (result.value) {
        this.actionLoading = true;
        const obj = {
          id: representative.userId,
          userStatus: status
        };
        this.service.UpdateEntityUserStatus(obj).subscribe(
          data => {
            this.actionLoading = false;
            this.getDetails();
            Swal.fire({
              title: this.translate.instant('messages.Success'),
              icon: 'success',
              confirmButtonText: this.translate.instant('messages.ok')
            });
          }, err => {
            this.actionLoading = false;
            console.error(err);
            this.getDetails();
            Swal.fire({
              title: this.translate.instant('messages.Error'),
              icon: 'error',
              confirmButtonText: this.translate.instant('messages.ok')
            });
          });
      }
    });
  }

  openEditDialog() {
    const dialogRef = this.dialog.open(CabsFormComponent, {
      width: '600px',
      data: {record: this.details}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getDetails();
        // Swal.fire({
        //   title: this.translate.instant('messages.Success'),
        //   text: this.translate.instant('CABs.CAB updated successfully'),
        //   icon: 'success',
        //   confirmButtonText: this.translate.instant('messages.ok')
        // }).then(() => {
        //   this.getDetails();
        // });
      }
    });
  }

  openTransactions() {
    const dialogRef = this.dialog.open(WalletTransDialogComponent, {
      width: '600px',
      data: {cabId: this.details.entityId}
    });
  }
  openCabPaymentDialog(transactionType) {
    const dialogRef = this.dialog.open(CabPaymentComponent, {
      width: '600px',
      data: {
        supposedAmount: this.details.registrationFees,
        duePaymentIds: this.duePayments,
        transactionType
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getDetails();
      }
    });
  }
  openCreateCertifcateDialog() {
    const dialogRef = this.dialog.open(CreateCertificatesComponent, {
      width: '600px',
      data: {create: true}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        Swal.fire({
          title: this.translate.instant('messages.Success'),
          text: this.translate.instant('CABs.Certificate created successfully'),
          icon: 'success',
          confirmButtonText: this.translate.instant('messages.ok')
        });
      }
    });
  }

  initName(name) {
    if (!name) { return ''; }
    let initials = name.match(/\b\w/g) || [];
    initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
    return initials;
  }

  openCreateRepDialog() {
    const dialogRef = this.dialog.open(CreateUserComponent, {
      width: '600px',
      data: {
        cabId: this.details.entityId,
        accountType: 2 // cab repr.
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.GetEntityUsersRepresentatives();
      }
    });
  }

  openEditRepresentative(representative) {
    const dialogRef = this.dialog.open(CreateUserComponent, {
      width: '600px',
      data: {
        userId: representative.userId,
        cabId: this.details.entityId,
        accountType: 2 // cab repr.
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.GetEntityUsersRepresentatives();
      }
    });
  }

  openChooseInvoicesDialog() {
    const dialogRef = this.dialog.open(ChooseInvoicesComponent, {
      width: '600px',
      data: {
        relatedData: this.details.payAccredationTransactionId,
        transactionType : 9
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getDetails();
      }
    });
  }
}
